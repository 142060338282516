<template>
    <b-card no-body style="padding: 32px 46px">
        <b-card-title style="font-size: 20px">
            {{$t('referral.how-it-works')}}
        </b-card-title>

        <b-card-body style="padding: 0px">
            <b-row>
                <div class="d-flex" style="justify-content: space-between;">
                    <about-card v-for="(card, index) in cards" :key="index" :item="card" class="item" />
                </div>
            </b-row>
            <b-row>
                    <warning-card />
            </b-row>
        </b-card-body>

    </b-card>
</template>

<script>
import AboutCard from "./AboutCard"
import WarningCard from "./WarningCard"

export default {
    data() {
        return {
            cards: [
                {
                    i: 1,
                    img: require('@/assets/images/site/icons/referral/about1.png'),
                    text: "referral.help-copy"
                },
                {
                    i: 2,
                    img: require('@/assets/images/site/icons/referral/about2.png'),
                    text: "referral.help-first-buy"
                },
                {
                    i: 3,
                    img: require('@/assets/images/site/icons/referral/about3.png'),
                    text: "referral.help-payment"
                }
            ]
        }
    },
    components: {
        AboutCard,
        WarningCard
    }


}
</script>

<style>
    .item{
        width:calc( ( 100% - 40px ) / 3 );
    }
</style>