<template>
  <b-card no-body class="p-1" style="border: 1px solid #EBECF2; box-shadow: 0px 10px 14px rgba(99, 109, 137, 0.05);">
      <img
        alt=""
        style="width: 48px; height: 48px"
        class="network_icon"
        :src="item.img"
      />

      <p class="mt-1 mb-0" v-html="$t(item.text)"></p>
  </b-card>
</template>

<script>
export default {
  props: {
    item: {type: Object}
  }
};
</script>

<style></style>
