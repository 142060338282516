<template>
    <b-card v-ripple no-body class="ref_card" style="margin-left: 16px; margin-right: 16px;" v-if="referral">

        <b-card-body style="padding: 16px 16px 0px 16px">
            <div style=" text-align: center; overflow:hidden;">
                <div class="d-flex" style="padding-right: 20px;">
                    <!-- <p style="width: 100%; font-size: 16px; font-weight: 600; color:#FFF; line-height: 28px;">Приводите друзей и зарабатывайте <br>до 10% на каждом контракте</p> -->
                    <i18n path="referral.invite-friend-mobile" style="width: 100%; font-size: 16px; font-weight: 600; color:#FFF; line-height: 28px;" tag="p">
                        <br>
                        <br>
                    </i18n>
                </div>

                <div class="d-flex">
                    <!-- <p style=" width: 100%;font-size: 12px; color:#FFF; line-height: 20px; opacity: 0.5">Вознаграждение выплачивается Qubity из своих средств</p> -->
                    <i18n path="referral.award-mobile" style="width: 100%;font-size: 12px; color:#FFF; line-height: 20px; opacity: 0.5;" tag="p">
                    </i18n>
                </div>

            
                <div class="d-flex justify-content-between" style="padding: 8px 12px; border-radius: 10px; color: #FFF; background: rgba(255, 255, 255, 0.1); margin-bottom: 1rem;"> 
                    <div ref="container" class="d-flex" style="flex-direction: column; text-align: left">
                        <span class="font-small-2" style="font-size: 10px; opacity: 0.5">
                           {{$t('referral.your-referal-link')}}:
                        </span>
                        <b-link :href="generate_url" class="text-ellipsis" style="color: #FFF">{{start_and_end(generate_url)}}</b-link>
                    </div>
                    <div style="margin: auto 12px; margin-right: 0;">
                        <img @click="copyUrl" alt="" src="~@/assets/images/icons/copy.png" style="width: 20px; cursor: pointer">
                    </div>
                </div>
                
                <div class="d-flex mb-1" style="flex-direction: column; align-items: center;">
                    <p style="font-size: 12px; color:#FFF; line-height: 18px; opacity: 0.5; margin-bottom: 6px">{{$t('referral.share')}}:</p>
                    <div class="share-ref d-flex">
                        <a :href="`https://t.me/share/url?url=${generate_url}&text=Реферальная программа Qubity.io`">
                            <img alt="" class="network_icon" src="@/assets/images/site/icons/networks_icons/telegram.svg">
                        </a>
                        <a :href="`https://vk.com/share.php?url=${generate_url}&title=Реферальная программа Qubity.io&image=https://qubity.io/images/mail-logo.png&noparse=true`">
                            <img alt="" class="network_icon" src="@/assets/images/site/icons/networks_icons/Group 2.svg">
                        </a>
                        <!-- <a href="/">
                            <img alt="" class="network_icon" src="@/assets/images/site/icons/networks_icons/Group 3.svg">
                        </a>
                        <a href="/">
                            <img alt="" class="network_icon" src="@/assets/images/site/icons/networks_icons/Group 4.svg">
                        </a>  -->
                    </div>
                </div>
                <img src="@/assets/images/site/elem-ref-card.png" style="width:100%"/>
            </div>

        </b-card-body>

        <div>
        </div>
    
        

    </b-card>
</template>

<script>
    
    import User from "@/modules/user/"
    import Blockchain from "@/modules/blockchain"
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
    
    export default {

        data() {
            return {
                User,
                Blockchain
            }
        },
        props: {
           referral: {
                type: Object
            }
        },
        methods: {
        copyUrl( args ) {
                
                let container = this.$refs.container;
                this.$copyText( this.generate_url, container );
                
                this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: this.$t('toasts.titles.copy'),
                      text: 'Ссылка успешно скопирована в буфер обмена.',
                      icon: 'CheckCircleIcon',
                      variant: 'success',
                    }
                });  
            },

            start_and_end(str, start = 10, end = 10) {
                if (str.length > 20) {
                  return str.substr(0, start) + '...' + str.substr(str.length - end , str.length);
                }
                return str;
            },
        },
        
        computed: {
              generate_url() {

                if (this.referral.ref !== null) {
                    return `${process.env.VUE_APP_BASE_URL}register/?ref=${this.referral.ref}`
                } 
                
                return ''

            },
          
            
        },
        
        components: {

        },
        watch: {

        },
        mounted() {

        }

    }

</script>

<style lang="scss" scoped>

    .ref_card {
        background-image: url(~@/assets/images/site/back-ref-card.png);
        background-size: cover;
        background-repeat: no-repeat, repeat;
        background-position: center;
        // height: 372px;
    }

    .custom-light {
        background-color: #FFF !important;
        color: #426FF6;
        border-color: #FFF !important;
        border-radius: 10px;
    }

    .close-ref-card {
        margin: auto auto auto 93%;
        background-color: #ffffff42;
        padding: 2px;
        border-radius: 50px;
    }

    .share-ref {
        a {
            margin: 0px 3px;
        }
    }
</style>