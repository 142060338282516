<template>
          
        <b-card v-if="bonusFund.fund.length > 0" no-body>
            <b-card-body style="padding: 32px 32px 0px 32px;">
                <b-card-title style="font-size: 20px">
                    {{$t('referral.bonus-fund')}}
                    <feather-icon 
                        v-b-popover.hover.top="$t('popovers.bonus-fund')"
                        icon="AlertCircleIcon" 
                        class="cursor-pointer text-muted" 
                    />
                </b-card-title>

                <b-row class="data-form" style="margin: 46px 0px;">
                    <b-col>

                        <div class="d-flex" style="position: absolute; top: -23px; width: 100%; padding-right: 35px;" >

                            <div v-for="(division, index) in bonusFund.fund" :key="index" class="d-flex" 
                                style="flex-direction: column; white-space: nowrap; margin-right: 6px" 
                                :style="{width: `${division.width}`}" 
                                :class="indexActive == index ? '' : 'text-muted'"
                            >
                                <div class="d-flex" style="width: 100%; justify-content: flex-end;">
                                    <span style="font-size: 10px;">{{division.ths}} TH/s</span>
                                </div>
                                <div class="d-flex" style="width: 100%; justify-content: flex-end; margin-top: 1.857rem">
                                    <span style="font-size: 10px; ">{{division.amount}} $</span>
                                </div>
                            </div>

                        </div>

                        <div class="d-flex bonus-progress" style="justify-content: space-between;">
                            
                            <b-progress  v-for="(division, index) in bonusFund.fund" :key="index" :max="division.ths" :style="{width: `${division.width}`}">
                                 <b-progress-bar :value="progress(index)"></b-progress-bar>
                            </b-progress>

                        </div>

                    </b-col>
                </b-row>
            </b-card-body>

        </b-card>
</template>

<script>
  export default {
    data() {
        return {
            indexActive: 0
        }
    },
    props: {
        bonusFund: {
            type: Object,
            default: {
                value: 0,
                fund: []
            }
        }
    },
    methods: {
        progress(i) {
            if (i === 0 ) { 
                return this.bonusFund.value; 
            }
            else {
                if (this.bonusFund.value >= this.bonusFund.fund[i].ths) {
                    return this.bonusFund.fund[i].ths;
                } else if (this.bonusFund.value > this.bonusFund.fund[i-1].ths) {
                    this.indexActive = i;
                    return this.bonusFund.value;
                } else {
                    return 0;
                }
            }
        },
    },
  }
</script>

<style lang="scss" scoped>

  .bonus-progress {
        .progress-bar {
           background: linear-gradient(249.98deg, #BD80F4 -47.44%, #426FF6 149.19%);
        }
    }

</style>