<template>
    <div>
        <div style="padding-left: 16px;">
            <p style="font-weight: 600; font-size: 20px; margin-bottom: 16px;">{{$t('referral.main-title')}}</p>
        </div>
        
        <referral-card-view :referral="referral" />
        <transactions-mobile-view ref="referral-transactions" :is_can_send_notification="referral.is_can_send_notification" @sender="sender" />
        <banner-card :referral="referral" class="mb-1" />
        <div style="margin-left: 16px; margin-right: 16px;">
            <b-button :href="partners_pdf" target="_blank" variant="outline-primary" block style="height: 44px; margin-bottom: 2rem; font-size: 14px; border-radius: 10px; padding-top: 14px; border-width: 2px !important;">{{$t('referral.btns.download-presentation')}}</b-button>
        </div>
        <bonus-funds-view ref="bonus-funds" :bonusFund="bonusFund" />
        <about-tablet-view />
        
    </div>
</template>

<script>
    import BannerCard from "./widgets/banner.mobile"
    import ReferralCardView from "./widgets/ReferralCard"
    import BonusFundsView from "./widgets/bonusfunds.mobile"
    import TransactionsMobileView from "./transactions/transactions.mobile"
    import AboutTabletView from "./widgets/about.mobile"

   export default {

        data() {
            return {
                referral: null,   
                bonusFund: {
                    value: 0,
                    fund: []
                },             
            }
        },
        methods: {
            
            closeModal( action ) {
                
                Object.keys(this.modalsVisibility).forEach(key => {
                    this.modalsVisibility[key] = false;
                });
                
            },
            
            showModal( action ) {
                
                Object.keys(this.modalsVisibility).forEach(key => {
                    this.modalsVisibility[key] = false;
                });
                
                if( this.modalsVisibility.hasOwnProperty(action) ) {
                    this.modalsVisibility[action] = true;
                }
                
            },
            
            
            update() {
                
            },
            
            get() {
                
                this.$request.get("referral.get").then( res => {
                    this.referral = res.item,
                    this.bonusFund = {
                        'value': res.item.total_ths,
                        'fund': res.fund
                    }
                });
                
            },

            sender(send) {
                this.referral.is_can_send_notification = send
                this.$request.post("notifications.sender", {
                    is_can_send_notification: this.referral.is_can_send_notification
                }).then( res => {
                }).catch(err => {
                });
            }
            
            
        },
        components: {
            ReferralCardView,
            TransactionsMobileView,
            BonusFundsView,
            BannerCard,
            AboutTabletView
        },
        
         computed: {
            
            partners_pdf() {
                return `${process.env.VUE_APP_BASE_URL}partners/pdf`;
            }
            
        },
        
        watch: {

        },
        mounted() {
            
          
            
            this.get();
            
        }

    }

</script>