<template>
        <b-card no-body v-if="referral" class="container-ref-card">
            <b-card-body class="ref-card" style="padding: 24px;">
                <div>
                    <p class="text-muted" style="margin-bottom: 6px; line-height: 20px;">{{$t('referral.earned')}}</p>                
                    <p style="margin-bottom: 6px; font-size: 28px; font-weight: 600; line-height: 32px;">{{ referral.balance.toFixed(8) }} ₿</p>
                </div>
                <div>
                    <p class="text-muted" style="margin-bottom: 6px; line-height: 20px;">{{$t('referral.invited')}}</p>
                    <p style="font-size: 28px; font-weight: 500; margin-top: 4px;">{{ referral.count_invitations }} <span style="font-size: 14px">{{ decl }}</span></p>
                </div>
            </b-card-body>
        </b-card>
</template>

<script>

    import User from "@/modules/user/"
    import Blockchain from "@/modules/blockchain"
    import Utils from "@/modules/utils/"


    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
    
    export default {

        data() {
            return {
                Utils
            }
        },
        props: {
            referral: {
                type: Object
            }
        },
        methods: {
            copyUrl( args ) {
                
                let container = this.$refs.container;
                this.$copyText( this.generate_url, container );
                
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: this.$t('toasts.titles.copy'),
                        text: 'Ссылка успешно скопирована в буфер обмена.',
                        icon: 'CheckCircleIcon',
                        variant: 'success',
                    }
                });  
            },

            start_and_end(str, start = 10, end = 10) {
                if (str.length > 20) {
                  return str.substr(0, start) + '...' + str.substr(str.length - end , str.length);
                }
                return str;
            },
        },
        components: {

        },
        computed: {
            
            symbol() {
                return User.getSymbol()
            },
            
            user() {
                return User.self;
            },
            
            currency_balance() {
                return (Blockchain.tickers.values[User.currency].last * this.referral.balance).formatMoney(2," "," ");
            },

            generate_url() {

                if (this.referral.ref !== null) {
                    return `${process.env.VUE_APP_BASE_URL}register/?ref=${this.referral.ref}`
                } 
                
                return ''

            },

            decl() {
                let display = [ 'реферал', 'реферала', 'рефералов' ];

                if (this.$i18n.locale !== 'ru') display = ['referral', 'referrals', 'referrals'];

                return Utils.declOfNum(this.referral.count_invitations, display);
            }

            
        },
        watch: {

        },
        mounted() {

        }

    }

</script>

<style lang="scss" scoped>

    .ref-card {
        display: flex;
        flex-direction: column;

        div:last-child {
            margin-top: 1.5rem;
        }
    }

    @media (max-width: 880px) {
        .ref-card {
            flex-direction: row;

            div:first-child {
                width: 50%;
            }

            div:last-child {
                margin-top: 0px;
            }
        }
    }

    @media (max-width: 530px) {
        .container-ref-card {
            margin-left: 16px;
            margin-right: 16px;
        }
        .ref-card {
            flex-direction: column;

            div:last-child {
                margin-top: 1.5rem;
            }

            div:first-child {
                width: 100%;
            }
        }
    }

</style>