<template>
    <div>

        <div>
            <p style="font-weight: 600; font-size: 20px; margin-bottom: 16px;">{{$t('referral.main-title')}}</p>
        </div>
        <div class="d-flex">
            <div  style="width: 300px;">
                <referral-card-view :referral="referral" />
                <banner-card :referral="referral" />
                <b-button :href="partners_pdf" target="_blank" variant="outline-primary" block style="height: 44px; margin-top: 24px; font-size: 14px; border-radius: 10px; padding-top: 14px; border-width: 2px !important;">{{$t('referral.btns.download-presentation')}}</b-button>
            </div>

            <div style="width: calc(100% - 300px); padding-left: 32px;">
                <transactions-desktop-view v-if="referral" ref="referral-transactions" :is_can_send_notification="referral.is_can_send_notification" @sender="sender" />
                <bonus-funds-view ref="bonus-funds" :bonusFund="bonusFund"/>
                <about-desktop-view />
            </div>
        </div>
        
    </div>
</template>

<script>

    import BannerCard from "./widgets/banner.desktop"
    import ReferralCardView from "./widgets/ReferralCard"
    import BonusFundsView from "./widgets/bonusfunds.desktop"
    import TransactionsDesktopView from "./transactions/transactions.desktop"
    import AboutDesktopView from "./widgets/about.desktop"

    
    export default {

        data() {
            return {
                referral: null,     
                bonusFund: {
                    value: 0,
                    fund: []
                },
            }
        },
        methods: {
            
            closeModal( action ) {
                
                Object.keys(this.modalsVisibility).forEach(key => {
                    this.modalsVisibility[key] = false;
                });
                
            },
            
            showModal( action ) {
                
                Object.keys(this.modalsVisibility).forEach(key => {
                    this.modalsVisibility[key] = false;
                });
                
                if( this.modalsVisibility.hasOwnProperty(action) ) {
                    this.modalsVisibility[action] = true;
                }
                
            },
            
            get() {
                
                this.$request.get("referral.get").then( res => {
                    this.referral = res.item
                    this.bonusFund = {
                        'value': res.item.total_ths,
                        'fund': res.fund
                    }
                }).catch(err => {
                    if (err.code == 4) this.$router.push({name: "user-dashboard"})
                });
                
            },

            sender(send) {
                this.referral.is_can_send_notification = send
                this.$request.post("notifications.sender", {
                    is_can_send_notification: this.referral.is_can_send_notification
                }).then( res => {
                }).catch(err => {
                });
            }
            
            
        },
        components: {
            ReferralCardView,
            BonusFundsView,
            TransactionsDesktopView,
            BannerCard,
            AboutDesktopView
        },
        
        computed: {
            
            partners_pdf() {
                return `${process.env.VUE_APP_BASE_URL}partners/pdf`;
            }
            
        },
        
        watch: {

        },
        mounted() {
            this.get();
        }

    }

</script>