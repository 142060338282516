<template>
    <b-card v-ripple no-body class="ref_card" v-if="referral">

        <b-card-body style="padding: 20px 24px 0px 24px;">
             <div class="d-flex">
                <div style="width: 100%">
            <div class="d-flex" style="">
                <i18n path="referral.invite-friend-mobile" style="font-size: 16px; font-weight: 600; color:#FFF; line-height: 22px;" tag="p">
                    <br>
                    <br>
                </i18n>
            </div>

            <div class="d-flex">
                <i18n path="referral.award" style="font-size: 12px; color:#FFF; line-height: 18px; opacity: 0.5" tag="p">
                </i18n>
            </div>

            <div class="d-flex justify-content-between" style="padding: 8px 12px; border-radius: 10px; color: #FFF; background: rgba(255, 255, 255, 0.1); margin-bottom: 1rem;"> 
                <div ref="container" class="d-flex" style="flex-direction: column">
                    <span class="font-small-2" style="font-size: 10px; opacity: 0.5">
                       {{$t('referral.your-referal-link')}}:
                    </span>
                    <b-link :href="generate_url" class="text-ellipsis" style="color: #FFF">{{start_and_end(generate_url)}}</b-link>
                </div>
                <div style="margin: auto 12px; margin-right: 0;">
                    <img @click="copyUrl" alt="" src="~@/assets/images/icons/copy.png" style="width: 20px; cursor: pointer">
                </div>
            </div>

            <div class="d-flex mb-1" style="flex-direction: column">
                <p style="font-size: 12px; color:#FFF; line-height: 18px; opacity: 0.5; margin-bottom: 6px">{{$t('referral.share')}}:</p>
                    <div class="share-ref d-flex">
                        <a :href="`https://t.me/share/url?url=${generate_url}&text=Реферальная программа Qubity.io`">
                            <img alt="" class="network_icon" src="@/assets/images/site/icons/networks_icons/telegram.svg">
                        </a>
                        <a :href="`https://vk.com/share.php?url=${generate_url}&title=Реферальная программа Qubity.io&image=https://qubity.io/images/mail-logo.png&noparse=true`">
                            <img alt="" class="network_icon" src="@/assets/images/site/icons/networks_icons/Group 2.svg">
                        </a>
                        <!-- <a href="/">
                            <img alt="" class="network_icon" src="@/assets/images/site/icons/networks_icons/Group 3.svg">
                        </a>
                        <a href="/">
                            <img alt="" class="network_icon" src="@/assets/images/site/icons/networks_icons/Group 4.svg">
                        </a>  -->
                    </div>
            </div>
</div>
                <div
                style="width: 100%;">
          <img src="@/assets/images/site/tab-elem-ref-card.png" alt="" style="width: 100%">
 </div>
             </div>
        </b-card-body>
    
    </b-card>
</template>

<script>
    
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
    
    export default {

        data() {
            return {
            }
        },
        props: {
           referral: {
                type: Object
            }
        },
        methods: {
           copyUrl( args ) {
                
                let container = this.$refs.container;
                this.$copyText( this.generate_url, container );
                
                this.$toast({
                    component: ToastificationContent,
                    props: {
                       title: this.$t('toasts.titles.copy'),
                      text: 'Ссылка успешно скопирована в буфер обмена.',
                      icon: 'CheckCircleIcon',
                      variant: 'success',
                    }
                });  
            },

            start_and_end(str, start = 10, end = 10) {
                if (str.length > 20) {
                  return str.substr(0, start) + '...' + str.substr(str.length - end , str.length);
                }
                return str;
            },
        },
        
        computed: {
            
          
            generate_url() {

                if (this.referral.ref !== null) {
                    return `${process.env.VUE_APP_BASE_URL}register/?ref=${this.referral.ref}`
                } 
                
                return ''

            },
            
        },
        
        components: {

        },
        watch: {

        },
        mounted() {

        }

    }

</script>

<style lang="scss" scoped>

    .ref_card {
        // background-image: url(~@/assets/images/site/ref-card.png);
        background-image: url(~@/assets/images/site/back-ref-card.png);
        background-size: cover;
        background-repeat: no-repeat, repeat;
        background-position: center;
    }

    .custom-light {
        background-color: #FFF !important;
        color: #426FF6;
        border-color: #FFF !important;
        border-radius: 10px;
    }

    .close-ref-card {
        margin: auto auto auto 93%;
        background-color: #ffffff42;
        padding: 2px;
        border-radius: 50px;
    }

    .share-ref {
        a {
            margin: 0px 3px;
        }
    }

</style>