<template>
  <b-card no-body class="mb-0 warning-ref-card">
        <b-card-body>
      <div class="d-flex warning-card" style="justify-content: space-between;">
            <div>
                <b-card-title>
                    {{$t('referral.warning-title')}}
                </b-card-title>

                <b-card-text class="warning-text-card">
                    {{$t('referral.warning-text')}}
                </b-card-text>
            </div>
            <div class="warning-icon-block">
              <img src="@/assets/images/site/icons/referral/cross.png" style="width: 90px; height: 90px"/>
            </div>
      </div>
        </b-card-body>
  </b-card>
</template>

<script>
export default {
};
</script>

<style lang="scss" scoped>
  .warning-text-card {
    padding-right: 30px;
  }

  .warning-ref-card {
    background: #FFE8E1; 
    width: 100%;
  }

  .dark-layout .warning-ref-card {
    background: #262642;
    border: 1px solid #FFF;
  }

  @media (max-width: 530px) {
    .warning-card {
      flex-direction: column;
    }

    .warning-icon-block {
      width: 100%;
      text-align: center;
      margin-top: 10px;
    }

    .warning-text-card {
      padding-right: 0px;
    }
  }
</style>