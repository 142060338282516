<template>
          
        <b-card no-body>
             <b-card-body>
                <b-card-header style="padding: 0px; margin-bottom: 32px">
                    <b-card-title style="font-size: 20px">
                        {{$t('referral.operations')}}
                    </b-card-title>
                    <b-form-checkbox
                        id="notif"
                        class="border-radius"
                        v-model="send_notifications"
                        name="checkbox-1"
                    >
                        {{$t('referral.send-notifications')}}
                    </b-form-checkbox>
                </b-card-header>

                <b-row>

                    <b-col cols="12" class="d-flex justify-content-between">
                        
                        <b-button-group>
                            <template v-for="(status, index) in statuses">
                                <b-button  @click="setStatus(status.value)" :variant="filter.status === status.value ? 'active' : 'inactive' " size="sm" style="height: 36px;">{{ $t(status.display) }}</b-button>
                                <div v-if="index < statuses.length - 1" class="delimiter"></div>
                            </template>
                        </b-button-group> 

                    </b-col>
                
                </b-row>

            </b-card-body>
            
            <b-overlay
                :show="is_loading"
                blur="50"
                variant='transparent'
                spinner-variant="primary"
                rounded="sm"
            >  
                         
                
                <b-card-body class='d-flex flex-column pb-5' v-if='notifications && notifications.items.length === 0' >
                    <div class="d-flex flex-column justify-content-center">
                        <div class="nodata-icon ml-auto mr-auto"></div>
                        <p class="text-muted mt-1 ml-auto mr-auto text-center">
                            {{$t('tables.no-data')}}
                        </p>
                    </div>
                </b-card-body>
                
                <b-table-simple v-else-if="notifications" id="table-transactions" responsive style="padding: 0 32px;" >

                    <b-thead>
                        <b-tr>
                            <b-th style="text-transform: uppercase; padding-left: 0px;">{{$t('tables.referral-actions')}}</b-th>
                            <b-th style="text-transform: uppercase; text-align: end; padding-right: 0px;">{{$t('tables.referral-credited-account')}}</b-th>
                        </b-tr>
                    </b-thead>

                    <b-tbody>
                        <b-tr v-for="(item, index) in notifications.items" :key="index">
                            <b-th class="text-black font-weight-normal" style="padding-left: 0px;">
                                <span v-if="!item.payment">
                                    <b>{{item.invate.firstName}} {{item.invate.lastName}} {{$t('referral.register')}}</b> {{$t('referral.by-referral-link')}}
                                </span>
                                <span v-else-if="item.contract.type == 1">
                                    <b>{{item.invate.firstName}} {{item.invate.lastName}} </b> {{$t('referral.purchased-contract')}} <b>{{$t('referral.of')}} {{item.contract.investments}}$</b>
                                </span>
                                <span v-else-if="item.contract.type == 2">
                                    <b>{{item.invate.firstName}} {{item.invate.lastName}} </b> {{$t('referral.purchased-equipment')}} <b>{{$t('referral.of')}} {{item.contract.investments}}$</b>
                                </span>
                            </b-th>
                            <b-th class="text-black font-weight-normal" style="text-align: end; padding-right: 0px;">
                                <span v-if="item.payment">
                                    <b>{{ getValueByTicker(item.payment.amount) }}{{symbol}}</b>
                                </span>
                            </b-th> 
                        </b-tr>
                    </b-tbody>

                </b-table-simple>

                <div class="custom-table-footer">
                    <center>
                        <b-button v-if="notifications.has_more_pages" @click="loadMore" variant="gray" style="margin: 21px; margin-right: 16px; border-radius: 10px; background-color: #FFF; border: 2px solid #EBECF2;">{{$t('referral.btns.more')}}</b-button>
                    </center>
                </div>
            
            </b-overlay>
        </b-card>
</template>

<script>
    
    import User from "@/modules/user/"
    import vSelect from 'vue-select'
    import DateRangePicker from 'vue2-daterange-picker'
    import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
    
    export default {
        props: {
            is_can_send_notification: {type: Boolean}
        },
        data() {
            return {
                
                User,
                
                ticker: "BTC",
                notifications: {
                    has_more_pages: false,
                    current_page: 1,
                    items: []
                },
                is_loading: true,
                send_notifications: false,

                filter: {
                    status: null
                },

                statuses: [
                    { value: null, display: "referral.tabs.all" },
                    { value: "miners", display: "referral.tabs.buy-miner" },
                    { value: "clouds", display: "referral.tabs.buy-cloud" },
                ],
                
            }
        },
        methods: {

            loadMore() {
                if(!this.notifications.has_more_pages) return;

                this.is_loading = true;
                this.$request.get("referral.notifications", {
                    page: this.notifications.current_page + 1, 
                    filter: this.filter
                } ).then( result => {
                    this.notifications.current_page = result.current_page;
                    this.notifications.has_more_pages = result.has_more_pages;
                    this.notifications.items.push( ...result.items );
                    this.is_loading = false;
                }).catch(err => {
                    this.is_loading = false;
                });
            },

            getValueByTicker( value ) {
                return (value * this.$blockchain.tickers.values[this.ticker].last).formatMoney(this.ticker === 'BTC' ? 8 : 2,' ', ' ');
            },
            
            start_and_end(str, start = 10, end = 5) {
                if (str.length > 20) {
                  return str.substr(0, start) + '...' + str.substr(str.length - end , str.length);
                }
                return str;
            },
            
            get() {
                this.is_loading = true;
                this.$request.get("referral.notifications", {filter: this.filter} ).then( result => {
                    this.notifications = result;
                    this.is_loading = false;
                }).catch(err => {
                    this.is_loading = false;
                });
            },

            setStatus( value ) {
                this.filter.status = value;
            },

        },
        filters: {
            date(val) {
              return val ? new Intl.DateTimeFormat('ru', { day: 'numeric',  year: '2-digit', month: 'numeric' }).format(val) : ''
            }
        },
        components: {
            vSelect,
            DateRangePicker
        },
        computed: {
            
            symbol() {
                if( this.ticker === "BTC" ) return "₿";
                return User.getSymbol();
            },
            
        },
        
        watch: {

            'filter': {
                deep: true,
                handler(bef) {
                    this.get();   
                }
            },
            'send_notifications': {
                handler(bef) {
                    this.$emit('sender', this.send_notifications);   
                }
            },
            
        },
        mounted() {
            this.get();
            this.send_notifications = this.is_can_send_notification;
        }

    }

</script>

<style lang="scss">
    
    @import '@core/scss/vue/libs/vue-select.scss';
    
     .reportrange-text {
        background-color: transparent !important;
        border: none !important;
    }
    
    .vue-daterange-picker {
        flex-grow: 1;
        /*height: 28px !important;*/
    }
    
    [dir] .daterangepicker td.in-range {
        background-color: #E4EAFD !important;
    }
    
    .dark-layout .daterangepicker td.in-range {
        background-color: #262642 !important;
        color: #FFF;
    }
    
    [dir] .daterangepicker td.active, [dir] .daterangepicker td.active:hover {
        background-color: #426FF6 !important;
    }
    
    [dir=ltr] .daterangepicker td.start-date {
        border-radius: 22px 0 0 22px;
    }
    
    [dir=ltr] .daterangepicker td.end-date {
        border-radius: 0 22px 22px 0;
    }
    
    [dir] .daterangepicker {
        border: none;
        border-radius: 16px;
        box-shadow: 0px 6px 40px rgba(99, 109, 137, 0.15);
    }
    
    .reportrange-text {
        margin: 0 !important;
        padding: 0 !important;
    }
    
    [dir=ltr] .custom-checkbox.b-custom-control-lg .custom-control-label::before, [dir=ltr] .input-group-lg .custom-checkbox .custom-control-label::before {
        left: 0px;
    }
    
    [dir=ltr] .custom-checkbox.b-custom-control-lg .custom-control-label::after, [dir=ltr] .input-group-lg .custom-checkbox .custom-control-label::after {
        left: -2px;
    }
    
    .dark-layout .daterangepicker .drp-buttons {
            border-top: 1px solid rgba(255,255,255,0.1);
    }
    
    .dark-layout .daterangepicker .calendar-table .next span, .dark-layout .daterangepicker .calendar-table .prev span {
        border-color: #fff;    
    }
    
</style>
 